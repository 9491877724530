import moment from 'moment'
import { EventBus } from '../events/eventBus'
import confirm from '../confirm/confirm.vue'

export default {
  name: 'Developments',
  data: () => ({
    loading: false
  }),

  components: {
    confirm: confirm
  },

  computed: {
    search () {
      return this.$store.getters['session/search']
    },
    developments () {
      let areas = this.$store.getters['areas/areas']

      if (this.search) {
        areas = areas.filter(area => {
          if (area.address || area.name) {
            return (
              // area.Address.toLowerCase().includes(this.search.toLowerCase()) ||
              area.name.toLowerCase().includes(this.search.toLowerCase())
            )
          } else {
            return false
          }
        })
      }
      return areas
    },
    isQA () {
      return this.$store.getters['session/getCognitoGroup']('qa')
    },
    isSiteManager () {
      return this.$store.getters['session/getCognitoGroup']('siteManager')
    }
  },
  async mounted () {
    const scope = this
    EventBus.$on('refreshData', async function (data) {
      await scope.getAreas()
    })
    await scope.getAreas()
  },
  methods: {
    formatDate (date) {
      if (date !== null && date !== undefined) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    getAreas: async function () {
      this.loading = true
      try {
        await this.$store.dispatch('areas/retrieveAreas')
      } catch (err) {
        this.$snotify.error(err.message)
      } finally {
        this.loading = false
      }
    },
    updateUrlQuery (development) {
      const developmentId = development.id
      this.$router.push({ path: `/developments/${developmentId}/issues` })
    },
    getTotalAreaIssuesCount (area) {
      let count = 0
      if (area.areas.length !== 0) {
        area.areas.forEach(subArea => {
          if (subArea.issueCounts.length !== 0) {
            count += this.sum(subArea.issueCounts)
          }
        })
      }
      return count
    },
    /**
     *
     * @returns Total of values in array.
     */
    sum (obj) {
      return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0)
    },
    getTotalPlotsCount (area) {
      let count = 0
      if (area.areas.length !== 0) {
        area.areas.forEach(subArea => {
          count += 1
        })
      }
      return count
    },
    getPlotStatus (plot) {
      // Removing any underscore or dash
      return plot.currentStatus.replace(/_|-/g, ' ')
    },
    updatedAt (issue) {
      return moment.utc(issue.updatedAt).local().format('DD-MM-YYYY @ HH:mm')
    },
    async approvePlot (plot) {
      this.$refs.confirm.open('Request Approval', 'Request will be sent to approvers Allan Clow and Jamie Tosh', { color: 'primary' }).then(async (confirm) => {
        if (confirm) {
          const copiedPlot = {
            id: plot.id,
            currentStatus: 'pending_approval'
          }
          const scope = this
          try {
            await scope.$store.dispatch('areas/updatePlot', copiedPlot)
            await scope.$store.dispatch('areas/retrieveAreas')
          } catch (err) {
            scope.$snotify.error(err.message, {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true
            })
          } finally {
            scope.loading = false
          }
        }
      })
    }
  },
  beforeDestroy () {
    EventBus.$off('refreshData')
  }
}
